#breathing-logo {
    -webkit-animation: breathing 1s ease-out infinite normal;
    animation: breathing 1s ease-out infinite normal;
       }


@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}


.dropzone {
  /* background: white; */
  border-radius: 5px;
  border: 2px dashed #00acc1;
  border-image: none;
  min-height: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.dropzoneError {
  /* background: white; */
  border-radius: 5px;
  border: 2px dashed red;
  border-image: none;
  min-height: 310px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.dropzone2 {
  /* background: white; */
  border-radius: 5px;
  border: 2px dashed #00acc1;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.dropzone2Error {
  /* background: white; */
  border-radius: 5px;
  border: 2px dashed red;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}


#myFont{  
  font-family: 'Roboto';
}


.LaboratoryChrome__network_reset_alert {
  background-color: black;
  color: white;
  border-radius: 0;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
}